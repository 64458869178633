<template>
  <base-page title="我的订单" titleEn="MY ORDER" :num="tableData.length">
    <!--<div class="vip-card">
      <div class="vip-card-t">
        <span>VIP会员</span>
        <div :class="[isVip ? 'vip-btn' :'novip-btn', 'btn']">{{isVip ? '会员续费' : '立即开通会员'}}</div>
      </div>
      <span class="vip-card-b">{{isVip ? '有效时间：2021-08-29 12:21' : '您还不是会员'}}</span>
    </div>-->
    <div class="pay-record">
      <!--      <span>VIP会员购买记录</span>-->
      <el-table :data="tableData" style="width: 100%">
        <el-table-column fixed prop="orderNumber" label="订单编号"></el-table-column>
        <el-table-column prop="orderTime" label="充值时间"></el-table-column>
        <el-table-column prop="expireTime" label="有效时间"></el-table-column>
        <el-table-column prop="orderAmount" label="付款金额（元）"></el-table-column>
      </el-table>
    </div>
  </base-page>
</template>

<script>
import basePage from '@/components/basePage.vue'
import {myOrder} from '@/api/api.js'

export default {
  name: 'MyOrder',
  components: {basePage},
  data () {
    return {
      isVip: true,
      tableData: []
    }
  },
  computed: {
    userId () {
      return this.$store.state.userInfo.userId
    }
  },
  watch: {
    userId: {
      handler: function (val, oldVal) {
        if (val) {
          this.getCollect(val)
        }
      },
      // 深度观察监听
      deep: true
    }
  },
  created () {
    if (this.$store.state.userInfo.userId) {
      this.getOrder(this.$store.state.userInfo.userId)
    }
  },
  methods: {
    getOrder (userId) {
      myOrder(userId).then((res) => {
        this.tableData = res.data.data
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.vip-card {
  position: relative;
  width: 270px;
  height: 160px;
  background: #ffffff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 16px 18px 21px 14px;
  box-sizing: border-box;
  margin-top: 80px;

  .vip-card-t {
    text-align: left;

    span {
      font-size: 20px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #000000;
      line-height: 28px;
    }

    .btn {
      display: inline-block;
      border-radius: 4px;
      width: 110px;
      height: 30px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      line-height: 28px;
      font-size: 14px;
      color: #ffffff;
      text-align: center;
      margin-left: 56px;
      cursor: pointer;
    }

    .vip-btn {
      background: #126ad7;
    }

    .novip-btn {
      background: #fd6501;
    }
  }

  .vip-card-b {
    position: absolute;
    left: 17px;
    bottom: 21px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
  }
}

/deep/ .pay-record {
  background: #ffffff;
  border: 1px solid #dadce0;
  border-radius: 5px;
  padding: 30px 49px 20px 28px;

  th,
  thead.is-group th {
    background: #fafafa;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
    height: 54px;
  }
}
</style>
