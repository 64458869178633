<template>
  <base-page title="搜索热度" titleEn="Search popularity" :num="total">
    <div class="sp-container">
      <el-table :data="tableData" style="width: 100%">
        <el-table-column type="index" label="排名" width="180"></el-table-column>
        <el-table-column prop="chineseCharacter" label="词条名称" width="260"></el-table-column>
        <el-table-column prop="statistics" label="搜索次数（次）"></el-table-column>
      </el-table>
    </div>
    <el-pagination
      class="sp-pagination"
      @size-change="changeSize"
      @current-change="changePage"
      background
      layout="prev, pager, next"
      :total="total"
    ></el-pagination>
  </base-page>
</template>

<script>
import basePage from "@/components/basePage.vue";
import { searchHot } from "@/api/api.js";

export default {
  name: "SearchPopular",
  components: { basePage },
  data() {
    return {
      tableData: [],
      pageNum: 1,
      pageSize: 10,
      total: 0
    };
  },
  created() {
    this.getData();
  },
  methods: {
    changeSize(val) {
      this.pageSize = val;
      this.getData();
    },
    changePage(val) {
      this.pageNum = val;
      this.getData();
    },
    getData() {
      const params = {
        pageNum: this.pageNum,
        pageSize: this.pageSize
      };
      searchHot(params).then(res => {
        this.tableData = res.data.data.dataList;
        this.total = Number(res.data.data.total);
      });
    }
  }
};
</script>

<style lang="scss" scoped>
/deep/ .sp-container {
  padding-top: 8px;
  margin-bottom: 30px;
  th,
  thead.is-group th {
    background: #fafafa;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
    height: 54px;
  }
  tr td:first-child {
    border-left: 1px solid #ebeef5;
  }
  tr td:last-child {
    border-right: 1px solid #ebeef5;
  }
}
.sp-pagination {
  padding-bottom: 30px;
}
</style>