<template>
  <div>
    <div class="dict">
      <div class="dict-main">
        <el-input placeholder="请输入需要搜索的内容"
          v-model="inputValue"
          class="search-input"
          clearable
          style="width: 796px; height: 56px"
          @input="handleInputWord"
          @focus="handleInputFocus"
          @blur="handleInputBlur"
          @keyup.enter.native="handleSearch(inputValue)">
          <el-button slot="append"
            icon="el-icon-search"
            style="width: 80px; color: #fff; fontsize: 25px"
            @click="handleSearch(inputValue)"></el-button>
        </el-input>
        <div v-if="searchNothing"
          style="padding: 20px">
          {{ searchNothing }}
        </div>
        <div v-if="
            inputValue != null &&
            inputValue.trim() != '' &&
            focusInput &&
            wordHots &&
            wordHots.length
          "
          class="search-res">
          <ul>
            <li v-for="(wordHot, index) in wordHots"
              :key="index"
              @click="handleSearch(wordHot.chineseCharacter), (wordHots = [])">
              <div class="search-res-l">
                <span class="word-ch">
                  <span v-for="(str, index) in wordHot.strs"
                    :key="index"
                    :class="[str === wordHot.searchValue ? 'prefix' : '']">{{ str }}
                  </span>
                </span>
                <span>{{ wordHot.Translation }}</span>
                <span class="search-res-r">已查询{{ " " + wordHot.statistics }}次</span>
              </div>
            </li>
          </ul>
        </div>
        <div class="recently-search"
          v-show="showRecently && searchHistory.length">
          <div class="recently-head">
            <span>最近搜索</span>
            <span class="recently-clear"
              @click="clearRecent">清空</span>
          </div>
          <div class="recently-list">
            <ul>
              <li v-for="(word, index) in searchHistory"
                :key="index">
                <div class="recently-word"
                  @click="addSearch(word)">
                  {{ word }}
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="dict-loading"
        v-show="dictLoading">
        <svg class="load"
          viewBox="25 25 50 50">
          <circle class="loading"
            cx="50"
            cy="50"
            r="20"
            fill="none" />
        </svg>
      </div>
      <div class="dict-content"
        v-if="searchRes.length > 0">
        <div class="relatedWords">
          <div class="relatedWords-title">搜索结果</div>
          <ul class="relatedWords-ul">
            <li style="cursor: pointer"
              v-for="(word, index) in searchRes"
              :key="index"
              @click="handleChooseWord(word, index)"
              :class="[
                'relatedWords-word',
                { 'relatedWords-word-active': currentWordIndex === index },
              ]">
              {{ word.chineseCharacter }}
            </li>
          </ul>
        </div>
        <div v-loading="loading"
          class="word-contain">
          <div class="word-outline">
            <div class="word-outline-h"
              v-if="searchResDetail.chineseCharacter">
              <span>【{{ searchResDetail.chineseCharacter }}】</span>
              <span>{{ searchResDetail.pinyinChIndex }}</span>
            </div>
            <div class="word-outline-s">
              <span v-if="searchResDetail.nature">&lt;{{ `${searchResDetail.nature}` }}&gt;</span>
            </div>
            <div class="word-outline-s">
              <span v-if="searchResDetail.radical"
                style="margin-right: 40px">部首：{{ searchResDetail.radical }}</span>
            </div>
            <div class="word-outline-d"></div>
          </div>
          <div class="interpretation">
            <div class="interpretation-head">
              <div class="interpretation-head-l">
                释义
                <span>源自：《汉外翻译大辞典》</span>
              </div>
              <div class="interpretation-head-r">
                <el-button :type="collected ? 'success' : 'primary'"
                  round
                  size="mini"
                  icon="el-icon-star-off"
                  @click="handleCollection">{{ collected ? "已收藏" : "收藏" }}</el-button>
                <el-button type="primary"
                  round
                  size="mini"
                  icon="el-icon-edit"
                  @click="!showCorrect && (errorCorrection = !errorCorrection)">纠错</el-button>
                <el-button type="primary"
                  round
                  size="mini"
                  icon="el-icon-search"
                  @click="handleView">查阅</el-button>
              </div>
            </div>
            <div class="interpretation-content">
              {{ searchResDetail.translation||'暂无' }}
              <i class="el-icon-edit"
                v-if="errorCorrection"
                @click="handleErrorCorrect">纠错</i>
            </div>
            <div class="interpretation-head mt30">
              <div class="interpretation-head-l">
                用法与辨析
              </div>
            </div>
            <div class="interpretation-content">
              {{ searchResDetail.wordUsage||'暂无' }}
            </div>
            <div class="correct-area"
              v-if="showCorrect">
              <input type="text"
                class="correct-input"
                placeholder="在此输入您的纠错信息"
                v-model="correctInput" />
              <i class="el-icon-close"
                @click="handleErrorCorrect"></i>
              <i class="el-icon-check"
                @click="sendCorrect"></i>
            </div>
          </div>
        </div>
      </div>
      <!--辞典释义-->
      <el-dialog :visible.sync="interpretationVisible"
        width="720px"
        custom-class="my-dialog"
        :append-to-body="true">
        <div class="interpretation-dia-head">辞典释义</div>
        <div class="interpretation-dia-main">
          <div class="dia-title">{{ searchResDetail.chineseCharacter }}</div>
          <div class="dia-content">{{ searchResDetail.translation }}</div>
        </div>
      </el-dialog>
      <!--微信登录弹框-->
      <el-dialog :visible.sync="dialogVisible"
        width="750px"
        :show-close="false"
        custom-class="my-dialog">
        <login @loginSuccess="loginSuccess" />
      </el-dialog>
    </div>
  </div>
</template>
<script>
import Login from "../../components/login.vue";
import { mapState } from "vuex";
import {
  collect,
  disableCollectWord,
  exactSearchWord,
  reportCorrection,
  searchWord,
  wordHot,
  wxlogin,
} from "@/api/api.js";

export default {
  components: { Login },
  data() {
    return {
      inputValue: "",
      dialogVisible: false,
      wordHots: [],
      searchRes: [],
      searchResDetail: {},
      interpretationVisible: false,
      searchHistory: [],
      showRecently: false,
      collected: false,
      searchNothing: "",
      errorCorrection: false,
      showCorrect: false,
      correctInput: "",
      focusInput: false,
      loading: false,
      dictLoading: false,
      currentWordIndex: 0,
    };
  },
  computed: {
    ...mapState(["userInfo"]),
  },
  methods: {
    // 点击搜索结果词条
    handleChooseWord(val, index) {
      this.currentWordIndex = index;
      this.searchResDetail = {};
      let consumerId = null;
      if (this.$store.state.userInfo && this.$store.state.userInfo.userId) {
        consumerId = this.$store.state.userInfo.userId;
      }
      let obj = {
        wordId: val.wordId,
        consumerId: consumerId,
      };
      this.loading = true;
      exactSearchWord(obj).then((res) => {
        this.loading = false;
        if (res.data.code == 0) {
          this.searchResDetail = res.data.data;
          this.collected = this.searchResDetail.collect;
        } else {
          this.$message({
            message: res.data.msg,
            type: "warning",
          });
        }
      });
    },
    handleInputWord(val) {
      if (val != null && val.trim() != "") {
        this.searchNothing = "";
        this.showRecently = false;
        wordHot(val).then((res) => {
          this.wordHots = res.data.data.map((item) => {
            const strs = [];
            var valIndex = item.chineseCharacter.indexOf(val);
            for (var i = 0; i < item.chineseCharacter.length; i++) {
              if (i == valIndex) {
                strs.push(val);
                i = valIndex + val.length - 1;
              } else {
                strs.push(item.chineseCharacter[i]);
              }
            }
            return {
              ...item,
              searchValue: val,
              strs,
            };
          });
        });
      }
    },
    handleInputFocus() {
      if (!this.inputValue) {
        this.showRecently = true;
      }
      this.handleInputWord(this.inputValue);
      this.focusInput = true;
    },
    handleInputBlur() {
      setTimeout(() => {
        this.focusInput = false;
        this.showRecently = false;
      }, 200);
    },
    handleSearch(val) {
      if (val != null && val.trim() != "") {
        window.location.replace(
          window.location.origin + window.location.pathname + "?word=" + val
        );
      }
    },
    // 搜索词条接口
    searchResult(val) {
      if (!val.trim()) return;
      this.dictLoading = true;
      this.searchRes = [];
      this.searchResDetail = {};
      this.inputValue = val;
      if (val != null && val.trim() != "") {
        let consumerId = null;
        if (this.$store.state.userInfo && this.$store.state.userInfo.userId) {
          consumerId = this.$store.state.userInfo.userId;
        }
        const params = {
          consumerId: consumerId,
          search: val,
          searchType: 0,
        };
        this.loading = true;
        searchWord(params)
          .then((res) => {
            if (res.data.code === 500) {
              this.searchNothing = "暂无当前词条";
              return;
            }
            if (res.data.code !== 0) {
              this.$message({
                message: res.data.msg,
                type: "error",
              });
              return;
            }
            // 词条塞入搜索历史
            const arr = localStorage.getItem("searchHistory");
            if (!arr.includes(val)) {
              this.searchHistory.push(val);
              localStorage.setItem(
                "searchHistory",
                JSON.stringify(this.searchHistory)
              );
            }

            // 搜索结果列表
            this.searchRes = res.data.data;
            if (this.searchRes != null && this.searchRes.length > 0) {
              this.searchResDetail = this.searchRes[0];
              this.collected = this.searchResDetail.collect;
            } else {
              this.searchNothing = "暂无当前词条";
            }
          })
          .catch((res) => {
            console.log(res);
            this.$message({
              message: "搜索词条出错",
              type: "error",
            });
          })
          .finally(() => {
            this.dictLoading = false;
            this.loading = false;
          });
      }
    },
    addSearch(val) {
      // this.inputValue = val;
      // this.showRecently = false;
      this.handleSearch(val);
    },
    clearRecent() {
      localStorage.setItem("searchHistory", JSON.stringify([]));
      this.searchHistory = [];
    },
    // 收藏
    handleCollection() {
      if (this.$store.state.userInfo && this.$store.state.userInfo.userId) {
        let wordId = this.searchResDetail.wordId;
        if (wordId == null || wordId == "") {
          this.$message({
            message: "单词id获取失败！",
            type: "error",
          });
          return false;
        }

        // 改变收藏状态
        const params = {
          consumerId: this.$store.state.userInfo.userId,
          wordId: wordId,
        };
        if (this.collected) {
          disableCollectWord(params).then((res) => {
            if (res.data.code === 0) {
              this.collected = false;
            }
          });
        } else {
          collect(params).then((res) => {
            if (res.data.code === 0) {
              this.collected = true;
            }
          });
        }
      } else {
        // 请登录
        this.dialogVisible = true;
      }
    },
    // 纠错
    handleErrorCorrect() {
      this.showCorrect = !this.showCorrect;
      this.errorCorrection = !this.errorCorrection;
    },
    // 调用纠错接口
    sendCorrect() {
      if (!this.correctInput) {
        this.$message.error("纠错信息不能为空");
      } else {
        if (this.$store.state.userInfo && this.$store.state.userInfo.userId) {
          const params = {
            consumerId: this.$store.state.userInfo.userId,
            correctionContent: this.correctInput,
            wordId: this.searchResDetail.wordId,
          };
          reportCorrection(params).then((res) => {
            this.handleErrorCorrect();
            this.$message.success("提交成功");
          });
        }
      }
    },
    // 查阅
    handleView() {
      if (localStorage.getItem("token")) {
        this.interpretationVisible = true;
      } else {
        this.dialogVisible = true;
      }
    },
    loginSuccess() {
      this.$store.state.isLogin = true;
      this.dialogVisible = false;
    },
  },
  created() {
    // 搜索历史
    this.searchHistory = JSON.parse(localStorage.getItem("searchHistory"));
    if (!this.searchHistory) {
      this.searchHistory = [];
      localStorage.setItem("searchHistory", JSON.stringify([]));
    }

    // 登录跳转
    var query = this.$route.query;
    if (query && query.sm) {
      wxlogin(query.sm).then((res) => {
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("userInfo", JSON.stringify(res.data.data));
        this.$store.state.userInfo = res.data.data;
        this.$store.state.isLogin = true;
      });
    } else if (query && query.word) {
      this.searchResult(query.word);
    }
  },
};
</script>
<style lang="scss" scoped>
.mt30 {
  margin-top: 30px;
}
.dict {
  width: 100%;
  margin: 0 auto;
  .dict-title-en {
    font-size: 38px;
    font-family: PoiretOne-Regular, PoiretOne;
    font-weight: 400;
    color: #2d396f;
    line-height: 45px;
    -webkit-text-stroke: 2px #2d396f;
    margin-top: 100px;

    img {
      width: 800px;
    }
  }

  .dict-main {
    position: relative;
    width: 800px;
    margin: 62px auto 0;
    .recently-search {
      width: 686px;
      left: 30px;
      padding: 24px 22px;
      box-shadow: 0px 0px 8px rgb(188, 188, 188);
      position: absolute;
      box-sizing: border-box;
      background-color: #fff;
      z-index: 2;

      .recently-head {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgb(100, 100, 100);
        text-align: left;

        .recently-clear {
          color: #cc4949;
          float: right;
          cursor: pointer;
        }
      }

      .recently-list {
        max-height: 170px;
        overflow-y: auto;
        .recently-word {
          width: 90px;
          height: 40px;
          background: #fafafa;
          border: 1px solid #ececec;
          float: left;
          line-height: 40px;
          margin: 16px 16px 0 0;
          padding: 0 5px;
          box-sizing: border-box;
          cursor: pointer;
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #000000;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          word-break: break-all;
        }
      }
    }

    .search-res {
      position: absolute;
      top: 60px;
      width: 686px;
      left: 30px;
      height: 260px;
      overflow-y: auto;
      background: #ffffff;
      box-shadow: 0px 0px 8px 0px rgb(200, 200, 200);
      padding: 0 0 10px 27px;
      box-sizing: border-box;
      border-radius: 5px;
      z-index: 5;
      li {
        height: 50px;
        line-height: 50px;
        border-bottom: 1px solid #f0f0f0;
        cursor: pointer;

        .search-res-l {
          text-align: left;

          .word-ch {
            margin-right: 10px;
            > span {
              font-size: 16px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #000000;
              &.prefix {
                color: #0780ed;
              }
            }
          }

          span {
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
          }

          .search-res-r {
            float: right;
            margin-right: 22px;
          }
        }
      }
    }
  }

  .dict-content {
    text-align: left;
    background-color: #fff;
    display: flex;
    margin: 53px auto 30px;
    width: 1060px;
    transform: translateX(-130px);
    .word-contain {
      width: 890px;
      float: right;
      .word-outline {
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        padding: 12px 20px 0 74px;
        .word-outline-h {
          font-size: 22px;
          color: #000;
          line-height: 36px;
          margin-bottom: 10px;
          margin-left: -10px;
        }
        span {
          font-size: 26px;
          color: #fd8701;
        }
        .word-outline-d {
          font-size: 22px;
          color: #444444;
          line-height: 36px;
          padding-bottom: 40px;
          border-bottom: 2px solid #d8d8d8;
          margin-bottom: 50px;
        }
        .word-outline-s {
          & span {
            font-size: 16px;
            color: black;
          }
        }
      }

      .interpretation {
        padding: 0 20px 20px 74px;
        .interpretation-head {
          line-height: 33px;
          height: 33px;
          .interpretation-head-l {
            float: left;
            font-size: 24px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #000000;
            span {
              font-size: 16px;
              font-weight: 400;
              color: #126ad7;
              vertical-align: middle;
              margin-left: 30px;
            }
          }

          .interpretation-head-r {
            float: right;
            .el-button {
              padding: 7px 10px;
            }
            .el-button--primary,
            .el-button--primary:focus,
            .el-button--primary:hover {
              color: #126ad7;
              background-color: #fff;
              box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.12);
              opacity: 1;
            }
            .el-button--success,
            .el-button--success:focus,
            .el-button--success:hover {
              color: #67c23a;
              background-color: #fff;
              opacity: 1;
            }
          }
        }

        .interpretation-content {
          margin-top: 20px;
          font-size: 20px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #868686;
          line-height: 40px;
          .el-icon-edit {
            color: #409eff;
            font-size: 14px;
            margin-left: 10px;
            cursor: pointer;
          }
        }

        .correct-area {
          position: relative;
          .correct-input {
            width: 583px;
            height: 40px;
            background: #f9fafe;
            border-radius: 4px;
            border: 1px solid #dddbdb;
            outline: none;
            padding-left: 50px;
          }
          &::after {
            content: "纠错";
            position: absolute;
            top: 12px;
            left: 10px;
            display: inline-block;
            background: #dadce0;
            border-radius: 19px;
            font-size: 12px;
            padding: 2px 5px;
          }
          .el-icon-close,
          .el-icon-check {
            font-size: 20px;
            margin-left: 10px;
            cursor: pointer;
          }
          .el-icon-check {
            color: #126ad7;
          }
        }
      }
    }

    .relatedWords {
      display: block;
      width: 170px;
      border-right: 2px solid #dcdcdc;
      text-align: right;
      float: left;
      .relatedWords-title {
        font-size: 24px;
        font-family: SourceHanSansCN-Bold, SourceHanSansCN;
        font-weight: bold;
        color: #2d396f;
        line-height: 36px;
        height: 36px;
        padding-right: 25px;
      }
      .relatedWords-ul {
        padding-right: 18px;
        overflow-y: scroll;
        min-height: 364px;
        height: calc(100vh - 325px);
        .relatedWords-word {
          font-size: 22px;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          line-height: 33px;
          color: #333;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          margin: 10px 0;
        }
        .relatedWords-word-active {
          color: #fd8701;
        }
      }
    }
  }

  .dict-loading {
    margin: 100px;
    .load {
      width: 80px;
      height: 80px;
      animation: rotate 2s linear infinite;
    }
    .loading {
      stroke: rgb(172, 213, 236);
      stroke-width: 5;
      fill: none;
      animation: dash 1.5s linear infinite;
    }
  }
}

/deep/ .search-input.el-input-group {
  box-shadow: 0px 2px 30px 0px rgba(21, 42, 67, 0.16);
  border-radius: 28px;
  overflow: hidden;
  > .el-input__inner {
    height: 56px;
    padding-left: 30px;
    border-top-left-radius: 28px;
    border-bottom-left-radius: 28px;
  }
  .el-input-group__append {
    background-color: #126ad7;
    border-radius: 0 28px 28px 0;
  }
  .el-icon-search::before {
    width: 20px;
    height: 20px;
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 130, 200;
    stroke-dashoffset: -50;
  }
  100% {
    stroke-dasharray: 130, 200;
    stroke-dashoffset: -188;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
<style lang="scss">
.interpretation-dia-head {
  width: 100%;
  height: 60px;
  background: #126ad7;
  box-shadow: 0px 0px 10px 0px rgba(10, 48, 89, 0.14);
  border-radius: 8px 8px 0px 0px;
  font-size: 20px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #fffdfd;
  line-height: 60px;
  text-align: center;
}

.interpretation-dia-main {
  width: 100%;
  height: 450px;
  padding: 43px 53px 64px 53px;
  box-sizing: border-box;
  position: relative;

  .dia-title {
    font-size: 26px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #fd8701;
    line-height: 37px;
    margin-bottom: 20px;
  }

  .dia-tip {
    position: absolute;
    width: 614px;
    bottom: 64px;
    text-align: center;

    span {
      color: #126ad7;
      cursor: pointer;
    }
  }
}
</style>