var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"dict"},[(_vm.searchRes.length == 0)?[_vm._m(0)]:_vm._e(),_c('div',{staticClass:"dict-main",staticStyle:{"margintop":"62px"}},[_c('el-input',{staticClass:"search-input",staticStyle:{"width":"796px","height":"56px"},attrs:{"placeholder":"请输入需要搜索的内容","clearable":""},on:{"input":_vm.handleInputWord,"focus":_vm.handleInputFocus,"blur":_vm.handleInputBlur},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.handleSearch(_vm.inputValue)}},model:{value:(_vm.inputValue),callback:function ($$v) {_vm.inputValue=$$v},expression:"inputValue"}},[_c('el-button',{staticStyle:{"width":"80px","color":"#fff","fontsize":"25px"},attrs:{"slot":"append","icon":"el-icon-search"},on:{"click":function($event){return _vm.handleSearch(_vm.inputValue)}},slot:"append"})],1),(
          _vm.inputValue != null &&
          _vm.inputValue.trim() != '' &&
          _vm.focusInput &&
          _vm.wordHots &&
          _vm.wordHots.length
        )?_c('div',{staticClass:"search-res"},[_c('ul',_vm._l((_vm.wordHots),function(wordHot,index){return _c('li',{key:index,on:{"click":function($event){_vm.handleSearch(wordHot.chineseCharacter), (_vm.wordHots = [])}}},[_c('div',{staticClass:"search-res-l"},[_c('span',{staticClass:"word-ch"},_vm._l((wordHot.strs),function(str,index){return _c('span',{key:index,class:[str === wordHot.searchValue ? 'prefix' : '']},[_vm._v(_vm._s(str))])}),0),_c('span',[_vm._v(_vm._s(wordHot.Translation))]),_c('span',{staticClass:"search-res-r"},[_vm._v("已查询"+_vm._s(" " + wordHot.statistics)+"次")])])])}),0)]):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showRecently && _vm.searchHistory.length),expression:"showRecently && searchHistory.length"}],staticClass:"recently-search"},[_c('div',{staticClass:"recently-head"},[_c('span',[_vm._v("最近搜索")]),_c('span',{staticClass:"recently-clear",on:{"click":_vm.clearRecent}},[_vm._v("清空")])]),_c('div',{staticClass:"recently-list"},[_c('ul',_vm._l((_vm.searchHistory),function(word,index){return _c('li',{key:index},[_c('div',{staticClass:"recently-word",on:{"click":function($event){return _vm.addSearch(word)}}},[_vm._v(" "+_vm._s(word)+" ")])])}),0)])])],1)],2),_c('div',{staticClass:"beian-tip",on:{"click":function($event){return _vm.openPage('https://beian.miit.gov.cn/')}}},[_vm._v("苏ICP备2024140657号-1")])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dict-title-en"},[_c('img',{attrs:{"src":require("@/assets/title@3x.png")}})])
}]

export { render, staticRenderFns }