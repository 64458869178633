<template>
  <div>
    <div class="dict">
      <template v-if="searchRes.length == 0">
        <div class="dict-title-en">
          <img src="@/assets/title@3x.png" />
        </div>
      </template>
      <div class="dict-main"
        style="margintop: 62px">
        <!--查询输入框-->
        <el-input placeholder="请输入需要搜索的内容"
          v-model="inputValue"
          class="search-input"
          clearable
          style="width: 796px; height: 56px"
          @input="handleInputWord"
          @focus="handleInputFocus"
          @blur="handleInputBlur"
          @keyup.enter.native="handleSearch(inputValue)">
          <el-button slot="append"
            icon="el-icon-search"
            style="width: 80px; color: #fff; fontsize: 25px"
            @click="handleSearch(inputValue)"></el-button>
        </el-input>
        <!--历史查询-->
        <div class="search-res"
          v-if="
            inputValue != null &&
            inputValue.trim() != '' &&
            focusInput &&
            wordHots &&
            wordHots.length
          ">
          <ul>
            <li v-for="(wordHot, index) in wordHots"
              :key="index"
              @click="handleSearch(wordHot.chineseCharacter), (wordHots = [])">
              <div class="search-res-l">
                <span class="word-ch">
                  <span v-for="(str, index) in wordHot.strs"
                    :key="index"
                    :class="[str === wordHot.searchValue ? 'prefix' : '']">{{ str }}</span>
                </span>
                <span>{{ wordHot.Translation }}</span>
                <span class="search-res-r">已查询{{ " " + wordHot.statistics }}次</span>
              </div>
            </li>
          </ul>
        </div>
        <!--最近搜索-->
        <div class="recently-search"
          v-show="showRecently && searchHistory.length">
          <div class="recently-head">
            <span>最近搜索</span>
            <span class="recently-clear"
              @click="clearRecent">清空</span>
          </div>
          <div class="recently-list">
            <ul>
              <li v-for="(word, index) in searchHistory"
                :key="index">
                <div class="recently-word"
                  @click="addSearch(word)">
                  {{ word }}
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>

    </div>

    <div class="beian-tip"
      @click="openPage('https://beian.miit.gov.cn/')">苏ICP备2024140657号-1</div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { wordHot, wxlogin } from "@/api/api.js";

export default {
  data() {
    return {
      inputValue: "",
      wordHots: [],
      searchRes: [],
      searchHistory: [],
      showRecently: false,
      focusInput: false,
    };
  },
  computed: {
    ...mapState(["userInfo"]),
  },
  methods: {
    openPage(href) {
      window.open(href)
    },
    handleInputWord(val) {
      if (val != null && val.trim() != "") {
        this.showRecently = false;
        wordHot(val).then((res) => {
          this.wordHots = res.data.data.map((item) => {
            const strs = [];
            var valIndex = item.chineseCharacter.indexOf(val);
            for (var i = 0; i < item.chineseCharacter.length; i++) {
              if (i == valIndex) {
                strs.push(val);
                i = valIndex + val.length - 1;
              } else {
                strs.push(item.chineseCharacter[i]);
              }
            }
            return {
              ...item,
              searchValue: val,
              strs,
            };
          });
        });
      }
    },
    handleInputFocus() {
      if (!this.inputValue) {
        this.showRecently = true;
      }
      this.handleInputWord(this.inputValue);
      this.focusInput = true;
    },
    handleInputBlur() {
      setTimeout(() => {
        this.showRecently = false;
        this.focusInput = false;
      }, 200);
    },
    handleSearch(val) {
      if (val != null && val.trim() != "") {
        this.$router.push({ path: "/dict", query: { word: val } });
      }
    },
    addSearch(val) {
      // this.inputValue = val;
      // this.showRecently = false;
      this.handleSearch(val);
    },
    clearRecent() {
      this.searchHistory = [];
      localStorage.setItem("searchHistory", JSON.stringify([]));
    },
  },
  created() {
    // 历史数据
    this.searchHistory = JSON.parse(localStorage.getItem("searchHistory"));
    if (!this.searchHistory) {
      this.searchHistory = [];
      localStorage.setItem("searchHistory", JSON.stringify([]));
    }

    // 登录跳转
    var query = this.$route.query;
    if (query && query.sm) {
      wxlogin(query.sm).then((res) => {
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("userInfo", JSON.stringify(res.data.token));
        this.$store.state.userInfo = res.data.data;
        this.$store.state.isLogin = true;
      });
    }
  },
};
</script>
<style lang="scss" scoped>
.dict {
  width: 800px;
  margin: 0 auto;
  padding: 45px 0 200px;
  position: relative;
  .dict-title-en {
    font-size: 38px;
    font-family: PoiretOne-Regular, PoiretOne;
    font-weight: 400;
    color: #2d396f;
    line-height: 45px;
    -webkit-text-stroke: 2px #2d396f;
    margin-top: 100px;

    img {
      width: 800px;
    }
  }

  .dict-main {
    position: relative;

    .recently-search {
      width: 686px;
      left: 30px;
      padding: 24px 22px;
      box-shadow: 0px 0px 8px rgb(188, 188, 188);
      position: absolute;
      box-sizing: border-box;
      background-color: #fff;
      z-index: 2;

      .recently-head {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgb(100, 100, 100);
        text-align: left;

        .recently-clear {
          color: #cc4949;
          float: right;
          cursor: pointer;
        }
      }

      .recently-list {
        max-height: 170px;
        overflow-y: auto;
        .recently-word {
          width: 90px;
          height: 40px;
          background: #fafafa;
          border: 1px solid #ececec;
          float: left;
          line-height: 40px;
          margin: 16px 16px 0 0;
          padding: 0 5px;
          box-sizing: border-box;
          cursor: pointer;
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #000000;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          word-break: break-all;
        }
      }
    }

    .search-res {
      position: absolute;
      top: 60px;
      width: 686px;
      left: 30px;
      height: 260px;
      overflow-y: auto;
      background: #ffffff;
      box-shadow: 0px 0px 8px 0px rgb(200, 200, 200);
      padding: 0 0 10px 27px;
      box-sizing: border-box;
      border-radius: 5px;
      z-index: 5;

      li {
        height: 50px;
        line-height: 50px;
        border-bottom: 1px solid #f0f0f0;
        cursor: pointer;

        .search-res-l {
          text-align: left;

          .word-ch {
            margin-right: 10px;
            > span {
              font-size: 16px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #000000;
              &.prefix {
                color: #0780ed;
              }
            }
          }

          span {
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
          }

          .search-res-r {
            float: right;
            margin-right: 22px;
          }
        }
      }
    }
  }
}
.beian-tip {
  position: fixed;
  font-size: 12px;
  cursor: pointer;
  text-decoration: underline;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  color: #fff;
  &:hover {
    color: #333;
  }
}
/deep/ .search-input.el-input-group {
  box-shadow: 0 0 15px #333;
  border-radius: 28px;
  overflow: hidden;
  > .el-input__inner {
    height: 56px;
    padding-left: 30px;
    border-top-left-radius: 28px;
    border-bottom-left-radius: 28px;
  }
  .el-input-group__append {
    background-color: #126ad7;
    border-radius: 0 28px 28px 0;
  }
  .el-icon-search::before {
    width: 20px;
    height: 20px;
  }
}
</style>
