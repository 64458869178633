<template>
  <div>
    <div class="introduction">
       <!-- <div v-html="this.$store.state.platform.contactUs"></div> -->
        <div class="page-banner">
        <div class="page-title">编辑委员会</div>
        <div class="page-detail">我们的大家庭，《智能双语翻译云》编辑委员</div>
      </div>
      <img class="contract-img" src="@/assets/edit.png">

    </div>   
    <div class="nav">
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.introduction {
  min-width: 1200px;
  text-align: left;

  .page-banner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 250px;
    background: url(../../assets/bg_img11@2x.png) no-repeat;
    background-size: cover;
    text-align: center;
    color: #fff;

    .page-title {
      font-size: 55px;
      font-weight: bold;
      letter-spacing: 10px;
      margin-bottom: 20px;
    }

    .page-detail {
      font-size: 26px;
      line-height: 40px;
    }
  }

  .contract-img {
    width: 100%;
  }
}
</style>