<template>
  <base-page title="问题反馈" titleEn="FEEDBACK" :num="tableData.length">
    <div class="fb-container">
      <el-table :data="tableData" style="width: 100%">
        <el-table-column prop="createTime" label="时间" width="200"></el-table-column>
        <el-table-column prop="feedbackContent" label="问题内容"></el-table-column>
      </el-table>
    </div>
  </base-page>
</template>

<script>
import basePage from "@/components/basePage.vue";
import { myFeedback } from "@/api/api.js"

export default {
  name: "Feedback",
  components: { basePage },
  data() {
    return {
      tableData: []
    };
  },
  created() {
    myFeedback(this.$store.state.userInfo.userId).then((res)=> {
      this.tableData = res.data.data
    })
  }
};
</script>

<style lang="scss" scoped>
/deep/ .fb-container {
  background: #ffffff;
  border: 1px solid #dadce0;
  border-radius: 5px;
  padding: 30px 49px 20px 28px;

  th,
  thead.is-group th {
    background: #fafafa;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
    height: 54px;
  }
}
</style>