<template>
  <div>
    <div class="introduction">
      <!-- <div v-html="this.$store.state.platform.platformIntroduction"></div> -->
      <div class="page-banner">
        <div class="page-title">关于我们</div>
        <div class="page-detail">本词典研编团队方阵详见《汉英翻译大词典》</div>
        <div class="page-detail">(译林出版社2015年) “编写人员” 名单</div>
      </div>
      <div class="page-content">
        <div class="page-content-section">
          <h1>吴文智 </h1>
          <p>主编，研究员，《江苏外语教学研究》杂志主编，资深翻译家（中国译协2018年表彰），中国译协四、五、六、七届理事，江苏省译协常务副会长。2009年获“江苏省建国六十年来外国语言文学与翻译研究成果”特别贡献奖。</p>
          <p>发表译文、论文百余篇，并出版专著、译著八十余部。所主编《产品说明书汉译英手册》获1991年“军队科技进步奖”二等奖，《实用汉英翻译词典》获“第五届国家辞书奖”二等奖。</p>
        </div>
        <div class="page-content-section">
          <h1>钱厚生 </h1>
          <p>主编，编审，曾任南京大学海外教育学院副院长、商务印书馆上海分馆总编辑、中国辞书学会辞书编辑出版专业委员会副主任，兼任英国伦敦大学学院名誉研究员、牛津大学出版社特约编审。</p>
          <p>发表语言学、词典学论文二十余篇，出版专著《英汉问候语告别语对比研究》、译著《古经今悟》(汉译英)等，主编《新英汉成语词典》《汉英对照中国古代名言词典》等。</p>
        </div>
        <div class="page-content-section">
          <h1>张柏然  </h1>
          <p>顾问（已故），南京大学外国语学院教授、博士生导师，资深翻译家、双语词典学家，曾作为富布赖特高级访问学者赴美国印第安那大学和哈佛大学进行学术研究。原任南京大学双语词典研究中心主任，兼任中国辞书学会副会长、中国翻译协会副会长。</p>
          <p>著有《译学卮言》等，所主编《新时代英汉大词典》荣获江苏省第九届哲学社会科学优秀成果一等奖等奖项。</p>
        </div>
        <div class="page-content-section">
          <h1>刘纯豹 </h1>
          <p>审订，南京大学大学外语部教授，长期从事词汇、文化、翻译和双语词典等方面的教学与研究,并在联合国科教文组织（UNESCO）从事过翻译工作。</p>
          <p>所主编《英语委婉语词典》荣获首届“中国辞书奖”二等奖。</p>
        </div>
      </div>
    </div>
    <div class="nav"></div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.introduction {
  min-width: 1200px;
  text-align: left;

  .page-banner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 250px;
    background: url(../../assets/bg_img11@2x.png) no-repeat;
    background-size: cover;
    text-align: center;
    color: #fff;

    .page-title {
      font-size: 55px;
      font-weight: bold;
      letter-spacing: 10px;
      margin-bottom: 20px;
    }

    .page-detail {
      font-size: 26px;
      line-height: 40px;
    }
  }

  .page-content {
    margin: 70px auto;
    width: 1300px;

    .page-content-section {
      box-sizing: border-box;
      display: inline-block;
      vertical-align: middle;
      width: 1300px;
      height: 420px;
      background: #f1f3fb;
      border-radius: 24px;
      font-size: 26px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #414E67;
      line-height: 46px;
      padding: 10px 50px;
      margin: 40px 0;
    }
  }
}
</style>