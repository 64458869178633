<template>
  <div class="base-page-container">
    <div class="bp-header">
      <div class="bp-msg">
        <div class="bp-msg-l">
          <span>{{title}}</span>
          / {{titleEn}}
        </div>
        <div class="bp-msg-r">{{title}} · {{num}}</div>
      </div>
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      require: true,
      type: String
    },
    titleEn: {
      require: true,
      type: String
    },
    num: {
      require: true,
      type: Number
    }
  }
};
</script>

<style lang="scss" scoped>
.base-page-container {
  width: 100%;
  height: 100%;
  padding: 62px 130px;
  box-sizing: border-box;

  .bp-header {
    width: 100%;
    height: 40px;
    margin-bottom: 20px;
    border-bottom: 3px solid #000;

    .bp-msg {
      width: 100%;
      .bp-msg-l {
        float: left;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #8590a6;
        line-height: 22px;
        span {
          font-size: 18px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #000000;
          line-height: 25px;
          margin-right: 10px;
        }
      }
      .bp-msg-r {
        float: right;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #126ad7;
        line-height: 20px;
      }
    }
  }
}
</style>