<template>
  <base-page title="收藏" titleEn="COLLECTS" :num="total">
    <ul class="collection-list" v-if="collections.length>0">
      <li v-for="(collection, index) in collections" :key="index" class="collection-cell" @click="viewWord(collection)">
        <div class="collection-img">
          <span>{{ collection.chineseCharacter }}</span>
        </div>
        <div class="collection-footer">
          <div class="collection-word">
            <span>{{ collection.chineseCharacter }}</span>
            {{ collection.translation }}
          </div>
          <div class="collection-detail">{{ collection.wordUsage }}</div>
          <div class="el-icon-star-on"></div>
        </div>
      </li>
    </ul>
    <span v-else>暂无数据</span>
    <div class="collection-dialog">
      <el-dialog :visible.sync="interpretationVisible" width="720px" custom-class="my-dialog" :append-to-body="true">
        <div class="interpretation-dia-head">辞典释义</div>
        <div class="interpretation-dia-main">
          <div class="dia-title">{{ word.chineseCharacter }}</div>
          <div class="dia-content">{{ word.translation }}</div>
          <!-- <div class="dia-tip">
            您现在还不是VIP会员，如果您还想查看更多《汉外翻译大辞典》其他释义，请先
            <span>升级VIP</span>
          </div>-->
        </div>
      </el-dialog>
    </div>
  </base-page>
</template>

<script>
import basePage from '@/components/basePage.vue'
import { getCollectList } from '@/api/api.js'

export default {
  name: 'SearchPopular',
  components: { basePage },
  data() {
    return {
      collections: [],
      total: 0,
      interpretationVisible: false,
      word: {
        chineseCharacter: '',
        translation: ''
      }
    }
  },

  created() {
    if (this.$store.state.userInfo.userId) {
      this.getCollect(this.$store.state.userInfo.userId)
    }
  },
  computed: {
    userId() {
      return this.$store.state.userInfo.userId
    }
  },
  watch: {
    userId: {
      handler: function (val, oldVal) {
        if (val) {
          this.getCollect(val)
        }
      },
      deep: true
    }
  },
  methods: {
    getCollect(userId) {
      const params = {
        consumerId: userId,
        pageNum: 1,
        pageSize: -1
      }
      getCollectList(params).then(res => {
        this.total = Number(res.data.data.total)
        this.collections = res.data.data.dataList
      })
    },
    viewWord(val) {
      if (val.chineseCharacter !== "") this.$router.push({ path: '/dict', query: { word: val.chineseCharacter } })
    }
  }
}
</script>

<style lang="scss" scoped>
.collection-list {
  display: flex;
  flex-wrap: wrap;
}
.collection-cell {
  box-sizing: border-box;
  width: 280px;
  height: 280px;
  margin: 10px 20px 30px 0;
  cursor: pointer;
  .collection-img {
    width: 279px;
    height: 249px;
    border: 1px solid #e8e8e8;
    border-radius: 8px;
    line-height: 200px;
    box-shadow: 0 0 5px #ddd;
    span {
      font-size: 60px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #000000;
      width: 100%;
      padding: 0 15px;
      box-sizing: border-box;
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-all;
    }
  }

  .collection-footer {
    position: relative;
    z-index: 2;
    height: 75px;
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.06);
    border-radius: 8px;
    margin-top: -44px;
    padding: 22px 16px 12px 18px;
    box-sizing: border-box;
    .collection-word {
      width: calc(100% - 30px);
      height: 22px;
      line-height: 22px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      span {
        font-size: 16px;
        font-weight: 500;
        color: #000000;
        vertical-align: middle;
        margin-right: 5px;
      }
    }

    .collection-detail {
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      line-height: 17px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .el-icon-star-on {
      position: absolute;
      top: 16px;
      right: 16px;
      font-size: 20px;
      color: #186ed7;
    }
  }
}

.collection-dialog {
  text-align: left;
  position: relative;
  z-index: 10000;

  .interpretation-dia-head {
    width: 100%;
    height: 60px;
    background: #126ad7;
    box-shadow: 0px 0px 10px 0px rgba(10, 48, 89, 0.14);
    border-radius: 8px 8px 0px 0px;
    font-size: 20px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #fffdfd;
    line-height: 60px;
    text-align: center;
  }

  .interpretation-dia-main {
    width: 100%;
    height: 450px;
    padding: 43px 53px 64px 53px;
    box-sizing: border-box;
    position: relative;

    .dia-title {
      font-size: 26px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #fd8701;
      line-height: 37px;
      margin-bottom: 20px;
    }

    .dia-tip {
      position: absolute;
      width: 614px;
      bottom: 64px;
      text-align: center;

      span {
        color: #126ad7;
        cursor: pointer;
      }
    }
  }
}
</style>
