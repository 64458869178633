<template>
  <div>
    <div class="introduction">
      <!-- <div v-html="this.$store.state.platform.platformIntroduction"></div> -->
      <div class="page-banner">
        <div class="page-title">平台介绍</div>
        <div class="page-detail">词典编排力求层次分明、版面清新、字体醒目、检索方便，</div>
        <div class="page-detail">尽可能让读者轻松查阅，一索即得。</div>
      </div>
      <div class="page-content">
        <img src="@/assets/编组 2@2x.png" class="page-content-img" />
        <div class="page-content-section">
          <p>
            本词典初版名为《实用汉英翻译词典》，曾荣获第五届国家辞书奖。现
            经十五年不断扩容、反复修订，更名为《汉英翻译大词典》，既突出汉
            英翻译实践之重点，又兼顾第二汉语教学之刚需。
          </p>
          <p>
            收词以译法多变的普通词语为主，广收习语、成语及新词，兼收政治、
            经济、文化、科技用语，共约7万条。
          </p>
          <p>
            译释以助升汉译英实际效果为导向，融语法学、语义学、语用学、翻译
            学知识于一体，力求更为适用。所有词目均先标明词性，再给出相应译
            法。同一词目若有多种词性，则逐一列出，分别加以译释。
          </p>
          <p>
            精心选配实际译例，并专备不含对应词的活译、巧译，置于◇之后，以
            利拓宽译路，达意传神。增设“用法与辨析”栏目，简要介绍英语词语
            之细微区别及用法要点，以便译者正确选用。配有多种特色附录，与词
            典正文相辅相成，便于领略中国历史、民俗、文化等方面重要信息。
          </p>
        </div>
      </div>
    </div>

    <div class="nav"></div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.introduction {
  min-width: 1200px;
  text-align: left;

  .page-banner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 250px;
    background: url(../../assets/bg_img11@2x.png) no-repeat;
    background-size: cover;
    text-align: center;
    color: #fff;

    .page-title {
      font-size: 55px;
      font-weight: bold;
      letter-spacing: 10px;
      margin-bottom: 20px;
    }

    .page-detail {
      font-size: 26px;
      line-height: 40px;
    }
  }

  .page-content {
    margin: 110px auto;
    width: 1300px;

    .page-content-img {
      width: 330px;
      margin: 55px 55px 55px 0;
      vertical-align: middle;
    }

    .page-content-section {
      box-sizing: border-box;
      display: inline-block;
      vertical-align: middle;
      width: 915px;
      height: 730px;
      background: #f1f3fb;
      border-radius: 24px;
      font-size: 26px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #414E67;
      line-height: 46px;
      padding: 10px 50px;
    }
  }
}
</style>